import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base-service';
import { Observable } from 'rxjs';
import { AttentionsHistory } from 'src/app/shared/interfaces/attentions-history';
import { MedicalOrder } from 'src/app/shared/interfaces/medical-order';
import { MedicalOrderViewModel } from 'src/app/shared/models/clinic-history/medical-order.model';
import { MEDICAL_REQUEST_TYPE } from 'src/app/shared/enums/medical-request-type.enum';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MedicalOrderService {

  private url = 'api/ordenes-medicas';

  constructor(private http: HttpBaseService) { }

  get(id: string) {
    const url = `${this.url}/por-verificar/${id}/historial-atencion`;
    return this.http.get<AttentionsHistory>(url);
  }

  getById(id: string): Observable<MedicalOrder> {
    const url = `${this.url}/${id}`;
    return this.http.get<MedicalOrder>(url);
  }

  getList(idPaciente: number, tipos: string) {
    const url = `${this.url}?idPaciente=${idPaciente}&idTiposSolicitud=${tipos}`;
    return this.http.get<MedicalOrderViewModel[]>(url);
  }

  buildRequestNumber(requestTypeId: number = -1, requestNumber: number = -1): string {
    return requestTypeId == MEDICAL_REQUEST_TYPE.MEDICAL_PRESCRIPTION ? requestNumber.toString().padStart(6, '0') : '';
  }

  generateByIdHashed(idHashed: string): Observable<string> {
    const url = `${this.url}/generacion`;
    const params = new HttpParams().set('hash', idHashed);

    return this.http.post<string>(url, null, { params }, false);
  }

}

export interface MedicalOrderSummary {
  idResponsableServicio: number;
  idTipoSolicitud: number;
  idVerificacion: string;
  nombrePaciente: string;
  nombreResponsableServicio: string;
  ubicacionFoto: string;

  idPaciente?:number;
  idUsuario: number;
  emailUsuario: string;
  idProveedorServicioAutenticacion: number;
}

export interface MedicalOrderValidatedUser extends MedicalOrderSummary {
  emailPaciente: string;
  fechaNacimiento: string;
  isValid: boolean;
  tipoRelacionPacienteUsuario: number;
}
