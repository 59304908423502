import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Theme, THEMES } from 'src/themes/theme';
import { BaseService } from './core/services/base.service';
import { DOCUMENT, Location } from '@angular/common';
import { LayoutService } from './core/services/layout.service';
import { Direction } from './shared/enums/direction.enum';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IframeMessageService } from './core/services/iframe-message.service';
import { EmptyDialogComponent } from './shared/components/dialogs/empty-dialog.component';
import { Debounce } from './shared/decorators/debounce.decorator';
import { Title, Meta } from '@angular/platform-browser';
import { UserService } from './core/services/user.service';
import { ActivatedRoute, ActivatedRouteSnapshot, ChildrenOutletContexts, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LoadChatMessage, LoginCompletedMessage, MessageBusService, PageScrollMessage, TerritorialDivionCountryMessage } from './core/services/message-bus.service';
import { VirtualAttentionService } from './core/services/virtual-attention.service';
import { AuthService } from './core/services/auth.service';
import { DialogData } from './shared/models/dialog-data.model';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { ConfirmDialogComponent } from './shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { DateAdapter } from '@angular/material/core';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { environment } from 'src/environments/environment';
import { fadeAnimation } from './shared/animations';
import { ThemeLoaderService } from './core/services/theme-loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackBarComponent } from './shared/components/custom-snack-bar/custom-snack-bar.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormComponentData } from './shared/models/people/form-control.model';
import { ContainerType } from './shared/enums/container-type.enum';
import { AppointmentsSignalRService } from './core/services/signalR/appointments-signalr.service';
import { AppointmentEvent } from './shared/models/signalr/appointment-event.model';
import { AppointmentService } from './core/services/appointment.service';
import { AppStorageService, STORAGE } from './core/services/app-storage.service';
import { TerritorialDivisionsService } from './core/services/territorial-divisions.service';
import { PatientAppointment } from './shared/models/process/appointment.model';
import { NextAppointmentDialogComponent } from './shared/components/dialogs/next-appointment-dialog/next-appointment-dialog.component';
import { SelectCountryDialogComponent } from './shared/components/dialogs/select-country-dialog/select-country-dialog.component';
import { ErrorDialogComponent } from './shared/components/dialogs/error-dialog/error-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ fadeAnimation ],
  providers: [AppointmentsSignalRService],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'PHR';
  headerName = '';
  directoryClass= 'cliniweb';
  iframeZIndex = 1;
  dummyDialog : MatDialogRef<any, any>;  
  showHeader = true;
  sessionExpiredPopupOpen = false;
  serviceErrorPopupOpen = false;
  isIframeModalOpen = false;
  hasJointGroup = false;
  lastUrl = '';

  routeDialogOpened: boolean = false;
  reloadPage: boolean = false;

  floatingComponents: FormComponentData[] = [];

  private selectCountryDialogOpened:boolean = false;

  private scrollTop: number = 0;
  private routeQueryParamsSubscribe:Subscription;

  ID_AREA_SISTEMA_MIS_CITAS: number = 3459;
  ID_AREA_SISTEMA_INICIO: number = 3457;
  ID_AREA_SISTEMA_DOCTORES: number = 3741;

  isDesktopMac: boolean = false;
  isIOS: boolean = false;

  pathName: string;

  constructor(
    @Inject(THEMES) public themes: Theme[],
    @Inject(DOCUMENT) private _document: any,
    private baseService: BaseService,
    private overlayContainer: OverlayContainer,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private iframeMessageService: IframeMessageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private matIconRegistry: MatIconRegistry,
    private titleService: Title,
    private userService: UserService,
    private messageBusService: MessageBusService,
    private authService: AuthService,       
    private virtualAttentionService: VirtualAttentionService,
    private appointmentService: AppointmentService,
    private themeLoaderService: ThemeLoaderService,
    private translation: L10nTranslationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private dateAdapter: DateAdapter<any>,
    private contexts: ChildrenOutletContexts,
    private meta: Meta,
    private snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
    private appointmentSignalRService: AppointmentsSignalRService,
    private appStorageService: AppStorageService,
    private territorialDivisionService: TerritorialDivisionsService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(DOCUMENT) private document: Document
  )  
  {
    this.matIconRegistry.registerFontClassAlias('phr-iconset');

    this.messageBusService.onAuthIframe().subscribe((a) =>{
      this.showHeader = false;
    });
    
    this.messageBusService.onHideHeader().subscribe((a) =>{
        this.showHeader = false;
        this.changeDetector.detectChanges();
    });

    this.messageBusService.onShowHeader().subscribe((a) =>{
      this.showHeader = true;
    });

    this.messageBusService.onOpenSnackBar().subscribe((message)=>{
      this.snackBar.openFromComponent(CustomSnackBarComponent, {
        data: message, 
        duration: message.duration,
        horizontalPosition: 'left'
      });      
    });

    this.messageBusService.onSendFloatingComponents().subscribe((message)=>{
      if(this.document.body.classList.contains('route-dialog-opened'))
        return;

      this.floatingComponents = message.componentsData.filter(c=>c.contenedor == ContainerType.MAIN);
    });

    if(environment.production){
      this.location.onUrlChange(url =>{
        let snapshotUrl = this.router.routerState.snapshot.url;
  
        let exceptedUrls = ['', '/', '/es', '/en'];
        let languages = ['es', 'en'];
  
        let isntExceptedUrl = exceptedUrls.filter(e => e == snapshotUrl).length == 0;
        let containsLanguage = languages.filter( l => l == url.split('/')[1]).length > 0;
        let sameUrlAsAbove = this.lastUrl && this.lastUrl == url;
        
        if(isntExceptedUrl && containsLanguage && !sameUrlAsAbove){
          this.lastUrl = url;
          this.googleAnalyticsService.logPageView(url);
        }
      });
    }

    this.router.events.subscribe((val)=>{

      if(val instanceof NavigationStart){
        if(document.body.classList.contains('route-dialog-opened') && val.navigationTrigger == 'popstate'){
          window.location.reload();
        }
      }

      if(val instanceof NavigationEnd){

        //add classname by page
        if(!document.body.classList.contains('route-dialog-opened')){

          var path = this.router.url.split('/')[1].split('?')[0];

          if(path != this.pathName){
            this.layoutService.removeClassToBody(this.pathName);

            this.pathName = path;

            this.layoutService.addClassToBody(this.pathName);
          }
        }

        // show header by data
        let showHeader = this.route.snapshot?.data?.showHeader;

        if(showHeader && showHeader == 'false'){
          this.showHeader = false;
        }
        
        if(this.reloadPage && !document.body.classList.contains('route-dialog-opened')){
          this.reloadPage = false;
          window.location.reload();
        }

        setTimeout(() => {
          if(document.body.classList.contains('route-dialog-opened')){
            this.routeDialogOpened = true;
          }
          else if(this.routeDialogOpened) {
            console.log('actualizar route config!');

            this.routeDialogOpened = false;
            this.reloadPage = true;
          }

          // Check if there is a next appointment alert to show
          this.checkNextAppointmentAlert();
        }, 600);


        if(!val.url.includes('checkout') 
          && this.appStorageService.getItemSession(STORAGE.SELECT_COUNTRY) 
          && !this.selectCountryDialogOpened){
          this.selectCountryDialogOpened = true;
          this.dialog.open(SelectCountryDialogComponent, {disableClose: true});
        }
      }
    });
  }

  ngOnInit(): void {
    //this.headerName = this.baseService.getHeaderName();
    this.setHeaderComponentName();

    this.toggleHeaderView();

    this.loadDirectoryTheme();

    this.setOverlayMaterial();

    this.setTitlePage();

    this.setFavicon();

    this.setManifestUrl();

    this.setIframeModalActions();

    this.manageVirtualAttentionActions();

    this.manageSessionExpiredAction();

    this.manageServiceErrorAction();

    this.setDateAdapter();

    this.startNewAppointmentsListener();

    this.setCountryByUser();

    this.setZIndexInitialValue();

    this.isIOS = this.baseService.isIOS();
    this.isDesktopMac = this.baseService.isMacDesktop();
  }

  private setHeaderComponentName() {
    if (location.pathname.indexOf("/perfil/") >= 0) {
      this.headerName = "PublicProfilePageHeaderComponent";
    }
    else {
      this.headerName = this.baseService.getHeaderName();
    }
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {

    if(this.routeQueryParamsSubscribe) {
      this.routeQueryParamsSubscribe.unsubscribe();
    }

    this.appointmentService.clearAppointmentsCache();
  }

  loadDirectoryTheme(){
    this.directoryClass = this.baseService.getDirectoryClass();

    this.document.body.classList.add(this.getDirectoryClassName());

    this.setThemeProperties();

    this.setThemeColor();

    this.themeLoaderService.init(this.directoryClass);
  }

  toggleHeaderView(){
    // Get QS parameters
    this.route.queryParams.subscribe(params => {
      if (params && params.hideHeader == 'true') {
        this.showHeader = false;
      }
    });
  }

  startNewAppointmentsListener() {
    this.userService.getUserPersonLoggedIn().subscribe(
      user => { user != null && user.id > 0 ? this.initAppointmentSignalR(user.id) : 0 },
      error => {}
    );
  }

  setCountryByUser(){
    this.messageBusService.onLoginCompleted().subscribe(message=>{

      this.userService.getUserCountry().subscribe(country=>{
        if(country){
          let msg = new TerritorialDivionCountryMessage();
          msg.country = country;
  
          this.messageBusService.sendTerritorialDivisionCountry(msg);
        }
        else{
          this.appStorageService.setItemSession(STORAGE.SELECT_COUNTRY, true);
        }
      });
    });
  }

  initAppointmentSignalR(userId: number) {
    if (!this.hasJointGroup) {
      this.appointmentSignalRService.onConnectionLost().subscribe(m => {
        console.log("Appointments: Connection lost");
      });
  
      this.appointmentSignalRService.onConnectionStablished().subscribe(m => {
      
        console.log("Appointments: Connection stablished");
        this.hasJointGroup = true;
        // Join group that will receive appointments
        this.appointmentSignalRService.joinGroup(userId);
      });
      
      // Init signalR
      this.appointmentSignalRService.init();
      
      // Subscribe to events
      this.subscribeToSignalREvents(userId);
    }
  }

  subscribeToSignalREvents(userId: number): void {  
  
    // NEW MESSAGE
    this.appointmentSignalRService.onAppointmentChangedReceived().subscribe((message: AppointmentEvent) => {        
      
      // second layer user validation
      if (message && message.IdUsuario == userId) {
        this.appointmentService.getAppointments(this.locale.language).subscribe(
          appointments => this.messageBusService.appointmentChangeReceived(appointments),
          error => {}
        );
      }
    });
  }    

  getRouteAnimationData(route?: ActivatedRouteSnapshot): string {
    if (!route) {
      route = this.contexts.getContext('primary')?.route?.snapshot;
    }
    
    let result = route?.data?.['animation'];
    
    if (route?.children?.length) {
      const response = result = this.getRouteAnimationData(route.children[0]);
      if (response) {
        result = response;
      }
    }

    return result ?? '';
  }

  getActiveVirtualAttention() {
    this.virtualAttentionService.getActive().subscribe(va => {      
      if (!va)
        return;
      
      let msg = new   LoadChatMessage();
      msg.idEtapaSolicitud = va.idEtapaSolicitud;
      
      this.messageBusService.loadChat(msg);
    });
  }

  setOverlayMaterial(){
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));
    if (themeClassesToRemove.length) {
       overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(this.getDirectoryClassName());
  }

  getDirectoryClassName(){
    return this.directoryClass + "-theme";
  }

  setThemeProperties(){
    const element = this._document.body;
    const theme = this.themes.find(t => t.name === this.directoryClass);

    if(!theme) return;

    for (const key in theme.properties) {
      element.style.setProperty(key, theme.properties[key]);
    }
  }

  setTitlePage(){
    const title = this.baseService.getDirectoryTitle();

    this.titleService.setTitle(title);
  }

  setFavicon(){
    const urlFavicon = this.baseService.getDirectoryUrlFavicon();

    const htmlElement:HTMLLinkElement | null  = document.querySelector('#faviconLink');
    const appleHtmlElement:HTMLLinkElement | null  = document.querySelector('#applefaviconLink');

    const currentTheme = this.themes.find(t => t.name === this.directoryClass);

    if(htmlElement) 
      htmlElement.href = urlFavicon;

    if (appleHtmlElement && currentTheme)
      appleHtmlElement.href = currentTheme.properties["--favicon-big"];
  }

  setManifestUrl(){
    const manifestUrl = "pwa/manifest/manifest-" + this.directoryClass + ".json";

    const htmlElement:HTMLLinkElement | null  = document.querySelector('#manifestLink');

    if(htmlElement)
      htmlElement.href = manifestUrl;
  }

  setThemeColor() {    
    const currentTheme = this.themes.find(t => t.name === this.directoryClass);

    this.meta.addTag({ name: 'theme-color', content: currentTheme?.properties["--primary"] });  
  }

  @HostListener('scroll', ['$event'])
  //@Debounce(100)
  onScroll($event:Event){

    const scrollTop = ($event.target as Element).scrollTop;
    const offset = this.scrollTop - scrollTop;
    const elOffsetTop = ($event.target as HTMLElement).offsetTop

    if(Math.abs(offset) < 50) return; 

    const direction = offset < 0
      ? Direction.Down
      : Direction.Up;

    this.scrollTop = scrollTop;

    this.layoutService.onScrollingBody(direction);

    this.messageBusService.pageScroll(new PageScrollMessage(direction, scrollTop, elOffsetTop, false));
  }

  @HostListener('touchmove', ['$event'])
  @Debounce(200)
  onTouchMove($event: Event) {
    const activeElement = this.document.activeElement;
    if (activeElement && (activeElement as HTMLElement).blur) {
      (activeElement as HTMLElement).blur();
    }
  }

  onDialogOpen() {    
    // This dummy dialog will provide the overlay we need on the page when openning a modal inside the iframe
    this.dummyDialog = this.dialog.open(EmptyDialogComponent, {
      panelClass: 'dummy-dialog',      
      disableClose: true
    });

    // We need to change the page main div z-index in this case to show the iframe above the overlay and modal we just onpened
    this.iframeZIndex = 9999;

    this.isIframeModalOpen = true;
  }

  onDialogClose() {
    if (this.dummyDialog)
      this.dummyDialog.close();

    //this.iframeZIndex = 1;
    this.setZIndexInitialValue();

    this.isIframeModalOpen = false;
  }

  setZIndexInitialValue() {
    /*let isMobile = window.innerWidth < 800;

    this.iframeZIndex = isMobile? 1 : 2;*/

    this.iframeZIndex = 1;
  }

  onRequestUserId() {
    this.userService.processRequestUserId();
  }

  private setIframeModalActions(){
      // Register to ModalOpen and ModalClose events that are fired from an iframe
      this.iframeMessageService.onModalOpen().subscribe(msg => {
        this.onDialogOpen();
      });
      this.iframeMessageService.onModalClose().subscribe(msg => {
        this.onDialogClose();
      });
  
      this.iframeMessageService.onRequestUserId().subscribe(msg => {
        this.onRequestUserId();
      });
  
      // We will get this event when user login using the popup of Cliniweb, as the PHR instance of the popup is in another window
      // Cliniweb need to send us a message to let us know the user loged in
      this.iframeMessageService.onRefreshUser().subscribe(m => {
        this.userService.getUserPersonLoggedIn().subscribe(p => {
          if (p)
          this.messageBusService.loginCompleted(new LoginCompletedMessage(p));
        });
      });
  }

  private manageVirtualAttentionActions(){
    // Get active virtual attention
    if (this.authService.isLoggedIn()) {
      this.getActiveVirtualAttention();
    }
    
    // On SSO when the ngOnInit the user is not yet logged in, so for those cases we will subscribe to this event
    this.messageBusService.onLoginCompleted().subscribe(msg => {      
      this.getActiveVirtualAttention();
      this.startNewAppointmentsListener();
    });
  }

  private manageSessionExpiredAction(){
    // On Session expired
    this.messageBusService.onSessionExpired().subscribe(msg => {

      if (this.sessionExpiredPopupOpen)
        return;
      
      this.sessionExpiredPopupOpen = true;

      let dialogData = new DialogData();
      dialogData.title = this.translation.translate("sessionExpired.title");
      dialogData.message = this.translation.translate("sessionExpired.message");
      dialogData.confirmText = this.translation.translate("sessionExpired.btnText");   
      dialogData.showHeaderIcon = false;
      //dialogData.headerIconClass = "phr-iconset-info";   
      dialogData.showCancelButton = false;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: dialogData,
        panelClass: ['cliniweb-theme-dialog', 'session-expired-dialog']
      });

      dialogRef.afterClosed().subscribe(accepted => {    
        this.sessionExpiredPopupOpen = false;
        
        this.authService.logout();
        this.appStorageService.removeSessionItem(STORAGE.SIGNUP_WORKFLOW_VERIFICATION_TYPE);
        window.location.href = `${window.location.origin}/login?homeUrl=${msg.returnUrl}`;
      });     
    });
  }

  private manageServiceErrorAction(){
    // On Service Error
    this.messageBusService.onServiceError().subscribe(msg => {

      if (msg.innerError && msg.innerError.message == "Popup window closed" || this.sessionExpiredPopupOpen) {
        // popups closed should not return error.
        return;
      }
      
      if (this.serviceErrorPopupOpen)
        return;
      
      this.serviceErrorPopupOpen = true;

      let messageText = msg.displayMessage? msg.displayMessage : this.translation.translate("serviceError.message");

      let dialogData = new DialogData();
      dialogData.title = this.translation.translate("serviceError.title");
      dialogData.message = messageText;
      dialogData.confirmText = this.translation.translate("serviceError.btnText");   
      dialogData.showHeaderIcon = true;
      dialogData.headerIconClass = "phr-iconset-warning";   
      dialogData.showCancelButton = false;

      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(accepted => {    
        this.serviceErrorPopupOpen = false;        
      });
    });
  }

  private setDateAdapter(){
    // When language change we need to set the propper locale to the datepicker
    this.dateAdapter.setLocale(this.locale.dateLanguage);

    this.baseService.localeChanged$.subscribe(l => {
      this.dateAdapter.setLocale(l.dateLanguage);
    });
  }

  private checkNextAppointmentAlert() {
    let companyName = this.baseService.getCompanyName();

    if (companyName.toLocaleLowerCase() == "sura-pacientes-pa")
      return;

    // If user is logged in
    this.userService.getUserPersonLoggedIn().subscribe(p => {
      if (!p)
        return;
      
        // If account has "citas" on the menu
        this.baseService.getMenuLinks(this.locale.language).subscribe(links => {
          if (links && links.length > 0 && links.find(l => l.idAreaSistema == this.ID_AREA_SISTEMA_MIS_CITAS)) {
            
            let path = document.location.pathname.replace(this.locale.language, "").replace(/\//g, "");            

            // If we are now on "Inicio" or "doctors" page
            if (links.find(l => 
                (l.idAreaSistema == this.ID_AREA_SISTEMA_INICIO || l.idAreaSistema == this.ID_AREA_SISTEMA_DOCTORES)
                && l.identificadorUrl == path
            )) {
              this.hasNextAppointmentAlert();
            }
          }
        });
    });
  }

  private hasNextAppointmentAlert() {
    this.appointmentService.getNextAppointment(this.locale.language).subscribe(appointment => {
      if (!appointment)
        return;

      let now = new Date().getTime();
      let lastTimeShown = this.appointmentService.getAppointmentLastTimeShown(appointment.idEtapaSolicitud);
      let creationDate = new Date(appointment.fechaRegistro).getTime();      
      
      if (lastTimeShown <= 0)
        lastTimeShown = creationDate;
      
      let timeDiff = now - lastTimeShown;
      let hrsDiff = (24 * 60 * 60 * 1000); // 24 hrs

      if (timeDiff > hrsDiff) {        
        this.appointmentService.setAppointmentLastTimeShown(appointment.idEtapaSolicitud);

        // Show the alert
        this.showNextAppointmentAlert(appointment);
      }
    });
  }

  showNextAppointmentAlert(appointment: PatientAppointment) {
    let dialogData = new DialogData();
    dialogData.data = appointment;
    
    const dialogRef = this.dialog.open(NextAppointmentDialogComponent, {
      data: {
        id: 'next-appointment-dialog',    
        data: appointment,
        animationIn: 'next-appointment-animation-in',
        animationOut: 'next-appointment-animation-out'
      },
      panelClass: ['next-appointment-dialog', 'next-appointment-animation-in']
    });
    
    dialogRef.afterClosed().subscribe(() => {

    });
  }
}
