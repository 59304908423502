import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DoctorSearchResult } from 'src/app/shared/models/cdr/doctor-search-result.model';
import { DoctorMessage } from 'src/app/shared/models/people/doctor-message.model';
import { PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';
import { HttpBaseService } from './http-base-service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  constructor(private http: HttpBaseService) { }

  searchDoctors(language: string, searchText: string, companyname: string, profileType: PublicProfileType[]):Observable<DoctorSearchResult[]> {

    if(!searchText)
      return of([]);

    let url = "api/doctores?localizacion=" + language + "&textoBusqueda=" + encodeURIComponent(searchText) + "&tipoPerfil=" + profileType.join(",");

    if (companyname)
      url += "&dominioEmpresa=" + companyname;

    return this.http.get<DoctorSearchResult[]>(url, null, false);
  }
  
  postMessageToDoctor(model: DoctorMessage) {
    let url = "api/doctores/" + model.IdPersona + "/mensaje";

    return this.http.post(url, model, null, false);
  }
  
  getLicense(idResponsableServicio: number): Observable<any> {
    const url = `api/doctores/${idResponsableServicio}/idoneidad`;
    const params = new HttpParams();

    return this.http.get(url, { params }, false);    
  }
}