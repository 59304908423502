import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";

export const fadeAnimation = 
    trigger('routeAnimations', [
        transition('SearchResultsPage => PublicProfilePage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
              style({
                position: 'absolute',
                top: 0,
                right: 0,
                width: '100%'
              })
            ],{ optional: true }),
            query(':enter', [
              style({ right: '-100%' })
            ],{ optional: true }),
            query(':leave', animateChild(), { optional: true }),
            group([
              query(':leave', [animate('400ms ease-out', style({ right: '100%' }))],{ optional: true }),
              query(':enter', [animate('400ms 300ms ease-out', style({ right: '0%' }))],{ optional: true }),
              query('@*', animateChild(), { optional: true })
            ]),
          ]),
        transition('PublicProfilePage => SearchResultsPage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
              style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%'
              })
            ],{ optional: true }),
            query(':enter', [
              style({ left: '-100%' })
            ],{ optional: true }),
            query(':leave', animateChild(), { optional: true }),
            group([
              query(':leave', [animate('400ms ease-out', style({ left: '100%' }))],{ optional: true }),
              query(':enter', [animate('400ms ease-out', style({ left: '0%' }))],{ optional: true }),
              query('@*', animateChild(), { optional: true })
            ]),
          ]),
        transition('PublicProfilePage => AppointmentsPage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
              style({
                position: 'absolute',
                top: 0,
                right: 0,
                width: '100%'
              })
            ],{ optional: true }),
            query(':enter', [
              style({ right: '-100%' })
            ],{ optional: true }),
            query(':leave', animateChild(), { optional: true }),
            group([
              query(':leave', [animate('400ms ease-out', style({ right: '100%' }))],{ optional: true }),
              query(':enter', [animate('400ms ease-out', style({ right: '0%' }))],{ optional: true }),
              query('@*', animateChild(), { optional: true })
            ]),
          ]),
        transition('AppointmentsPage => PublicProfilePage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
              style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%'
              })
            ],{ optional: true }),
            query(':enter', [style({ left: '-100%' })],{ optional: true }),
            query(':leave', animateChild(), { optional: true }),
            group([
              query(':leave', [animate('400ms ease-out', style({ left: '100%' }))],{ optional: true }),
              query(':enter', [animate('400ms ease-out', style({ left: '0%' }))],{ optional: true }),
              query('@*', animateChild(), { optional: true })
            ]),
          ]),
          transition('MedicalOrderLandingPage => *', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
              style({
                position: 'absolute',
                top: 0,
                right: 0,
                height: '100%',
                width: '100%'
              })
            ], { optional: true }),
            query(':enter', [
              style({ right: '-100%', backgroundColor: 'white' }),
              animate('600ms ease-in', style({ right: '0%' }))
            ], { optional: true }),
          ]),
          transition('* => MedicalOrderLandingPage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
              style({
                position: 'absolute',
                top: 0,
                right: 0,
                height: '100%',
                width: '100%'
              })
            ], { optional: true }),
            query(':enter', [
              style({ right: '-100%', backgroundColor: 'white' }),
              animate('600ms ease-in', style({ right: '0%' }))
            ], { optional: true }),
          ]),
    ]);

export const inOutAnimation = trigger(
  'inOutAnimation', 
  [
    transition(':enter', [
      style({ transform: 'translateY(100%)' }),
      animate('300ms', style({transform: 'translateY(0)'}))
    ]),
    transition(':leave', [
      style({transform: 'translateY(0)'}),
      animate('300ms', style({transform: 'translateY(100%)'}))
    ])
  ]
);

/*export const inOutAnimationWithDelay = trigger(
  'inOutAnimationWithDelay', 
  [
    transition('* => PublicProfilePage', [
      style({ transform: 'translateX(100%)' }),
      animate('300ms 300ms', style({transform: 'translateX(0)'})),
    ]),
    transition(':leave', [
      style({transform: 'translateX(0)'}),
      animate('300ms', style({transform: 'translateX(100%)'}))
    ])
  ]
);*/